import {useClubStore} from "@/application/store/useClubStore";
import {clubRepository} from "@/infrastructure/repositories/club.repository";
import Club from "@/domain/models/Club";
import {RouteLocationNormalized, NavigationGuardNext} from 'vue-router';
import {storage} from "@/infrastructure/storage/storage";

export async function setClubFromStorage(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const club = storage.get('dashboardClub')
    const parsedClub = club ? JSON.parse(club) : null
    const clubStore = useClubStore()

    try {

        if (clubStore?.getClub && clubStore.getClub.id) {
            next();
            return;
        }

        if (parsedClub && parsedClub.id) {
            const club = await clubRepository.show(parsedClub.id);
            if (club instanceof Club) {
                await clubStore.setClub(club);
                next();
                return;
            }
        }

    } catch (e) {
        next('/home')
        console.error(e);
    }

    next('/home')
}
