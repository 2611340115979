import { RouteRecordRaw } from 'vue-router'
import i18nConfig from '@/i18n-config'
import {setClubFromStorage} from "@/application/router/guards/setClubFromStorage";

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'CleanLayout',
        component: () => import('../layouts/CleanLayout.vue'),
        meta: {
            middleware: 'translation'
        },
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import('@/application/views/auth/Login.vue'),
            },
            {
                name: 'ResetPassword',
                path: i18nConfig.instance.t('routes.resetPasswordToken'),
                component: () => import('@/application/views/auth/ResetPassword.vue'),
                props: true
            },
        ]
    },
    {
        path: '/',
        name: 'DashboardLayout',
        component: () => import('../layouts/DashboardLayout.vue'),
        meta: {
            middleware: 'auth|translation'
        },
        children: [
            {
                path: i18nConfig.instance.t('routes.home'),
                name: 'DashboardHome',
                component: () => import('@/application/views/Index.vue'),
            },
            {
                path: '/',
                name: 'DashboardProfile',
                component: () => import('@/application/views/profile/Profile.vue'),
                children: [
                    {
                        path: i18nConfig.instance.t('routes.profile'),
                        name: 'UserProfile',
                        component: () => import('@/application/views/profile/UserProfile.vue'),
                    },
                ]
            },
            {
                path: i18nConfig.instance.t('routes.calendar'),
                beforeEnter: setClubFromStorage,
                name: 'DashboardBookings',
                component: () => import('@/application/views/bookings/Bookings.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.settings'),
                beforeEnter: setClubFromStorage,
                name: 'Settings',
                component: () => import('@/application/views/Settings.vue'),
            },
        ]
    },
]