import { apiService } from '@/domain/services/api.service'
import { UserMapper } from '../http/dto/UserDTO'

export const userRepository = {
  getUser: async () => {
    try {
      const response = await apiService.privateGet('users/show')
      if (response.content.body?.id) {
        return UserMapper.toDTO(response.content.body)
      }
    } catch (error) {
      return null
    }
  },
  update: async (data: any) => {
    return await apiService.privatePost('users/update', data)
  },
  delete: async () => {
    return await apiService.destroy('users/delete')
  },
  updateAvatar: async (formData: any) => {
    return await apiService.privatePostWithFile('users/update-avatar', formData)
  },
  sendResetPasswordEmail: async (email: string) => {
    return await apiService.publicPost('users/send-password-reset', { email: email })
  },
  resetPassword: async (data: any) => {
    return await apiService.publicPost('users/reset-password', data)
  },
  globalLogout: async () => {
    return await apiService.privateGet('users/global-logout')
  },
  createStripeAccount: async () => {
    return await apiService.privateGet('users/create-stripe-account')
  },
}
