import { storage } from '@/infrastructure/storage/storage'
import User from '@/domain/models/User'
import { authRepository } from '@/infrastructure/repositories/auth.repository'
import { useUserStore } from '@/application/store/useUserStore'
import { userRepository } from '@/infrastructure/repositories/user.repository'
import Helper from "@/domain/shared/Helper";

export const authService = {
  getToken: (): string => {
    return storage.get('token') as string
  },
  setToken: (token: string) => {
    storage.set('token', token)
  },
  logout: async() => {
    const response = await authRepository.logout()
    const store = useUserStore()

    if (response.ok) {
      store.isPlayerLogged = false
      store.isClubLogged = false
      store.currentUser = null
      await Helper.redirect('/')
    }
  },
  getUserAuth: async () => {
    const user = await userRepository.getUser()
    return user ? new User(user) : null
  },
  setCurrentUser: (user: User) => {
    const store = useUserStore()
    store.setCurrentUser(user)
    store.setIsClubLogged(true)
    storage.set('currentUser', JSON.stringify(user))
  },
  getCurrentUser: () => {
    return storage.get('currentUser') ? new User(JSON.parse(storage.get('currentUser') as string)) : null
  },
}
